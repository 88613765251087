<template>
  <div class="accountOverview" :class="{ visitStyle: visitStyle }">
    <div class="account-header-border">
      <div class="account-header">
        <div class="account-header-left">
          <div class="account-header-logo" @click="gotoFun('/Navigation')">
            <svg-icon icon-class="pc-logo" class="pc-logo-style"></svg-icon>
          </div>
          <div class="account-header-title">
            <span></span>
          </div>
          <div class="workbench">
            <span class="workbench-name">{{ workbench }}</span>
            <svg-icon
              icon-class="jiantou-bottom"
              class="jiantou-bottom"
            ></svg-icon>
            <div class="workbench-popup">
              <span class="arrow-up workbench-arrow-up"></span>
              <div class="workbench-popup-mian">
                <div
                  class="workbench-popup-box"
                  v-for="(item, index) in paginationList"
                  :key="index"
                  @click="gotoFun(item.path, index, item)"
                >
                  <div
                    v-if="index < 6"
                    class="workbench-svg-box"
                    :class="{ disable: item.index === 99 }"
                  >
                    <svg-icon :icon-class="item.icon"></svg-icon>
                  </div>
                  <p v-if="index < 6">{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="account-header-right">
          <companySelect></companySelect>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="account-content">
      <!-- <TheFooter></TheFooter> -->
      <router-view />
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/companySelect.vue";
export default {
  name: "Home",
  data() {
    return {
      workbench: "",
      paginationList: [
        {
          index: 2,
          left: false,
          right: false,
          title: "财务",
          icon: "da-caishui",
          path: "/balance",
          type: "4",
        },
        {
          index: 0,
          left: true,
          right: true,
          title: "花名册",
          icon: "da-huamingce",
          path: "/roster",
          type: "2",
        },
        {
          index: 1,
          left: false,
          right: false,
          title: "工资",
          icon: "da-gongzi",
          path: "/payrollModule",
          type: "3",
        },
        {
          index: 3,
          left: false,
          right: false,
          title: "银行对账单",
          icon: "xiao-yinhangduizhangdan",
          path: "/bankFlow",
          type: "5",
        },
        {
          index: 43,
          left: false,
          right: false,
          title: "考勤",
          icon: "xiao-yinhangduizhangdan",
          path: "/attendanceGroup",
          type: "6",
        },

        {
          index: 99,
          left: false,
          right: false,
          title: "开票",
          icon: "xiao-kaipiao",
          path: "",
        },

        {
          index: 99,
          left: false,
          right: false,
          title: "企业信息",
          icon: "xiao-kaipiao",
          path: "",
        },
        {
          index: 99,
          left: false,
          right: false,
          title: "报税表",
          icon: "xiao-kaipiao",
          path: "",
        },
      ],
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      visit: sessionStorage.getItem("visit"),
      visitStyle: false,
    };
  },
  components: {
    companySelect,
  },
  watch: {
    $route(to, from) {
      console.log();
      this.workbench = this.$route.meta.workbench;
      if (this.$route.meta.style === "visit" && this.visit === "visit") {
        this.visitStyle = true;
      } else {
        this.visitStyle = false;
      }
    },
  },
  mounted() {
    this.workbench = this.$route.meta.workbench;
    if (this.$route.meta.style === "visit" && this.visit === "visit") {
      this.visitStyle = true;
    } else {
      this.visitStyle = false;
    }
  },
  methods: {
    gotoFun(path, index, item) {
      //* 1、判断权限，进行提示
      if (path === "/Navigation") {
        this.$router.push(path);
        return;
      }
      if (this.jurisdiction === "1") {
        if (path) {
          this.$router.push(path);
        }
      } else {
        if (item.type === "5") {
          if (
            this.jurisdiction.indexOf(item.type) != -1 ||
            this.jurisdiction.indexOf("4") != -1
          ) {
            this.$router.push(item.path);
          }
        } else {
          if (this.jurisdiction.indexOf(item.type) != -1) {
            this.$router.push(item.path);
          } else {
            this.$store.commit("MyMessage", "暂无权限");
          }
        }
      }
    },
  },
};
</script>
<style lang="less">
.visitStyle {
  .account-header {
    width: calc(100% - 104px) !important;
  }
  .views-mian-header,
  .views-mian-content {
    width: calc(100% - 104px) !important;
  }
}
</style>
<style lang="less" scoped>
.account-header-border {
  border-bottom: 1px solid #dbdbdb;
  background: #fafafa;
  width: 100%;
}
.accountOverview {
  width: 100%;
  height: 100%;
  // min-width: 1482px;
  min-width: 1328px;
}

.account-header {
  width: calc(100% - 104px);
  width: 1328px;
  margin: 0 auto;
  padding: 0 52px;
  height: 60px;
  // box-shadow: 0px 2px 1px rgba(219, 219, 219, 1);
  // z-index: -1;
  .account-header-left {
    padding: 10px 0;
    float: left;
    width: 45%;
  }
  .account-header-right {
    padding: 10px 0 10px;
    float: right;
    width: 55%;
  }
  .account-header-logo {
    height: 40px;
    width: 104px;
    float: left;
    cursor: pointer;
    .pc-logo-style {
      height: 40px !important;
      width: 104px !important;
      position: relative;
      // top: 6px;
    }
  }
  .account-header-title {
    height: 40px;
    line-height: 40px;
    float: left;
    font-size: 20px;
    font-weight: bold;
    color: #262626;
    span {
      width: 1px;
      height: 21px;
      display: inline-block;
      background: #262626;
      margin: 0 25px;
      position: relative;
      top: 4px;
    }
  }
}
.account-content {
  width: 100%;
  height: calc(100% - 82px);
  // z-index: 10;
  position: relative;
}
.workbench {
  display: inline-block;
  // margin-left: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: #262626;
  cursor: pointer;
  position: relative;
  height: 40px;
  .workbench-name {
    font-size: 24px;
    font-weight: bold;
  }
  &:hover {
    .workbench-popup {
      display: block;
    }
  }
  .workbench-popup {
    display: none;
    position: absolute;
    left: -50px;
    top: 20px;
    width: 430px;
    padding-top: 40px;
    // height: 340px;
    z-index: 9999999;
    .workbench-arrow-up {
      top: -13px;
      left: 64px;
    }
    .workbench-popup-mian {
      padding: 30px 40px 0;
      width: calc(100% - 80px);
      text-align: center;
      overflow: hidden;

      background: #ffffff;
      box-shadow: 0px 3px 15px #9da5b2;
      .workbench-popup-box {
        width: calc(100% / 3);
        float: left;
        margin-bottom: 40px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          height: 36px;
          line-height: 36px;
          border: 1px solid #fff;
          width: 90px;
          border-radius: 11px;
          margin: 0 auto;
        }
        &:hover {
          p {
            background: #e5f2fe;
            border: 1px solid #84b9ff;
          }
        }
        .workbench-svg-box {
          width: 73px;
          height: 70px;
          margin: 0 auto 15px;
          &.disable {
            opacity: 0.2;
          }
          background: linear-gradient(
            180deg,
            #47b8eb 0%,
            #4983f3 67%,
            #4072dc 100%
          );
          opacity: 1;
          border-radius: 18px;
          font-size: 64px;
          padding-top: 3px;
        }
      }
    }
  }
}
.jiantou-bottom {
  padding-left: 10px;
}
</style>

